const NoPage = (): JSX.Element => {
  return (
    <main className="w-full top-0 bottom-0 fixed overflow-x-hidden z-2000 bg-[#1b3140] bg-gradient-to-br from-[#396d92] via-[#1e3647] to-[rgba(27,49,64,0)] block box-border">
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        <svg aria-hidden="true" className="w-[100px] h-[100px] mb-4" viewBox="0 0 68 29">
          <path d="M0 0v29h8.18l-.021-9.375h8.03v-7.912H8.226V7.929H18L17.463 0zM57.98 0l-3.519 6.261L51.01 0h-9.518l8.323 13.998L41 28.983l9.251.017 4.097-7.348 4.149 7.331H68l-8.978-15.082L67.181 0z"></path><path d="M31.04 22.089a2.376 2.376 0 0 1-2.377-2.376l.003-11.47c0-1.311 1.061-2.485 2.374-2.485 1.311 0 2.478 1.174 2.478 2.486v11.502c0 1.313-1.167 2.343-2.479 2.343M31 0c-7.732 0-14 6.268-14 14s6.268 14 14 14 14-6.268 14-14S38.733 0 31 0"></path>
        </svg>
        <h1 className="text-2xl font-bold text-gray-700">Page Not Found</h1>
      </div>
    </main>
  )
}

export default NoPage
