import { AxiosResponse } from 'axios'
import { Client } from '../Axios/Axios'
import { StatusResponse } from '../../Interfaces/EmailStatusAPI/StatusResponse'

const EmailStatusAPI = {
  async CheckEmailStatus (email: string): Promise<AxiosResponse<StatusResponse>> {
    const path = '/status'
    return await Client.get(path, {
      params: {
        email
      }
    })
  }
}

export default EmailStatusAPI
