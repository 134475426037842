
import commonStyles from '../../Styles/Common.module.css'
import MainContainer from '../../Components/MainContainer/MainContainer'

const Confirm = (): JSX.Element => {
  return (
    <MainContainer>
      <div>
        <h1 className={commonStyles.FoxH1}>You are now logged in</h1>
        <h2 className={commonStyles.FoxTxt}>You can go back to the original application</h2>
      </div>
    </MainContainer>
  )
}
export default Confirm
