import { useContext } from 'react'
import { ThemeContext } from '../../Context/theme'

interface Props {
  children: JSX.Element
}

const Context = ({ children }: Props): JSX.Element => {
  const { theme } = useContext(ThemeContext)
  const bgColor = theme?.context_background_color

  return (
    <div style={{ backgroundColor: bgColor }} className="m-t-0 m-b-auto mx-auto flex items-center flex-col w-[calc(100%-2.5rem)] relative h-auto">
      { children }
    </div>
  )
}

export default Context
