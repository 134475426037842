import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { LogoutRequest, CompletedRequest } from '@ory/hydra-client'

import styles from './Logout.module.css'
import commonStyles from '../../Styles/Common.module.css'
import { HydraAdmin } from '../../Config/Config'
import MainContainer from '../../Components/MainContainer/MainContainer'

const ERROR_URL = '/error'

const Logout = (): JSX.Element => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const logoutChallenge = searchParams.get('challenge') ?? ''
  const [logoutResp, setLogoutResp] = useState<LogoutRequest>()

  useEffect(() => {
    getLogoutRequestInfo(logoutChallenge)
      .then((logoutInfo) => setLogoutResp(logoutInfo))
      .catch(() => console.log('error'))
  }, [])

  console.log(logoutResp)

  return (
    <MainContainer>
      <div>
        <h1 className={commonStyles.FoxH1}>Are you sure you want to logout?</h1>
        <br />
        <div className={styles.ButtonsContainer}>
          <input
            className={commonStyles.FoxButton}
            type="button"
            value="Yes"
            onClick={() => {
              acceptLogout(logoutChallenge)
                .then((data) => window.location.replace(data.redirect_to))
                .catch(() => navigate(ERROR_URL))
            }}
          />
          <input
            className={commonStyles.FoxButton}
            type="button"
            value="No"
            onClick={() => {
              rejectLogout(logoutChallenge)
                .catch(() => navigate(ERROR_URL))
            }}
          />
        </div>
      </div>
    </MainContainer>
  )
}

async function getLogoutRequestInfo (logoutChallenge: string): Promise<LogoutRequest> {
  return await HydraAdmin.getLogoutRequest(logoutChallenge)
    .then((r) => r.data)
}

async function acceptLogout (logoutChallenge: string): Promise<CompletedRequest> {
  return await HydraAdmin.acceptLogoutRequest(logoutChallenge)
    .then((r) => r.data)
}

async function rejectLogout (logoutChallenge: string): Promise<any> {
  return await HydraAdmin.rejectLogoutRequest(logoutChallenge)
    .then((r) => r.data)
}

export default Logout
