import { AxiosResponse } from 'axios'
import { Client } from '../Axios/Axios'
import { IdpLoginRequest, IdpLoginResponse, IdpRegisterRequest, IdpRegisterResponse } from '../../Interfaces/'

const FoxIdpAPI = {
  async IdpLogin (loginRequest: IdpLoginRequest): Promise<AxiosResponse<IdpLoginResponse>> {
    const path = '/login-idp'
    return await Client.post(path, loginRequest)
  },
  async IdpRegister (idpRegisterRequest?: IdpRegisterRequest): Promise<AxiosResponse<IdpRegisterResponse>> {
    const path = '/register-idp'
    return await Client.post(path, idpRegisterRequest)
  }
}

export default FoxIdpAPI
