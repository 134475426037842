import Logout from './Logout/Logout'
import NoPage from './NoPage/NoPage'
import Consent from './Consent/Consent'
import Callback from './Callback/Callback'
import Confirm from './Confirm/Confirm'
import Home from './Home/Home'
import Authenticate from './Authenticate/Authenticate'

const Pages = {
  Logout,
  NoPage,
  Consent,
  Callback,
  Confirm,
  Home,
  Authenticate
}

export default Pages
