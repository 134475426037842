import { HydraAdmin } from '../../Config/Config'
import { Client } from '../Axios/Axios'
import { AxiosResponse } from 'axios'
import { ExtendedLoginRequest } from '../../Interfaces/IndentityHydraAPI/ExtendedLoginRequest'
import { CompletedRequest, ConsentRequest } from '@ory/hydra-client'

const IdentityHydraAPI = {
  async GetLoginRequestInfo (loginChallenge: string): Promise<AxiosResponse<ExtendedLoginRequest>> {
    if (loginChallenge === '') throw new Error('empty login challenge')
    const path = `/oauth2/auth/requests/login?login_challenge=${loginChallenge}`
    return await Client.get<ExtendedLoginRequest>(path)
  },
  async AcceptLoginRequest (loginChallenge: string, userEmail: string): Promise<CompletedRequest> {
    if (loginChallenge === '') throw new Error('empty login challenge')
    if (userEmail === '') throw new Error('email is empty')
    return await HydraAdmin.acceptLoginRequest(loginChallenge, { subject: userEmail, remember: true, remember_for: 3600 })
      .then((r) => r.data)
  },
  async RejectLoginRequest (loginChallenge: string): Promise<CompletedRequest> {
    if (loginChallenge === '') throw new Error('empty login challenge')
    return await HydraAdmin.rejectLoginRequest(loginChallenge)
      .then((r) => r.data)
  },
  async GetConsentRequest (consentChallenge: string): Promise<ConsentRequest> {
    if (consentChallenge === '') throw new Error('empty consent challenge')
    return await HydraAdmin.getConsentRequest(consentChallenge)
      .then((r) => r.data)
  }
}

export default IdentityHydraAPI
