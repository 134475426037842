import axios from 'axios'
import { ApiConfig } from '../../Config/Config'

const Client = axios.create({
  headers: { 'X-Api-Key': ApiConfig.ApiKey },
  baseURL: ApiConfig.ApiBasePath,
  timeout: ApiConfig.Timeout
})

export {
  Client
}
