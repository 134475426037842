import { useContext } from 'react'
import { ThemeContext } from '../../Context/theme'

const Header = (): JSX.Element => {
  const { theme } = useContext(ThemeContext)
  const logoURL = theme?.header_logo
  const bgColor = theme?.header_background_color

  return (
    <div className="w-full h-24 pl-16 flex items-center" style={{ backgroundColor: bgColor }}>
      <a className="cursor-pointer">
        <img className="w-auto h-8" src={logoURL} alt="fox logo" title="FOX"/>
      </a>
    </div>
  )
}

export default Header
