import { ReportHandler } from 'web-vitals'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/space-before-function-paren
function reportWebVitals(onPerfEntry?: ReportHandler) {
  const newLocal = onPerfEntry != null && onPerfEntry instanceof Function
  if (newLocal) {
    void import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry)
      getFID(onPerfEntry)
      getFCP(onPerfEntry)
      getLCP(onPerfEntry)
      getTTFB(onPerfEntry)
    })
  }
}

export default reportWebVitals
