import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from './Context/theme'
import Pages from './Pages'
import { ApiConfig } from './Config/Config'

declare global {
  interface Window {
    grecaptcha: any
  }
}

const App = (): JSX.Element => {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          {ApiConfig.Environment !== 'PROD' && (
               <Route path="/home" element={<Pages.Home />} />
          )}
          <Route path="/auth" element={<Pages.Authenticate />}/>
          <Route path="/logout" element={<Pages.Logout />} />
          <Route path="/consent" element={<Pages.Consent />} />
          {ApiConfig.Environment !== 'PROD' && (
               <Route path="/callback" element={<Pages.Callback />} />
          )}
          <Route path="/confirm" element={<Pages.Confirm />} />
          <Route path="*" element={<Pages.NoPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default App
