import Context from '../Context/Context'
import Header from '../Header/Header'

interface Props {
  children: JSX.Element
}

const MainContainer = ({ children }: Props): JSX.Element => {
  return (
    <div className="w-full top-0 bottom-0 fixed overflow-x-hidden z-2000 bg-[#1b3140] bg-gradient-to-br from-[#396d92] via-[#1e3647] to-[rgba(27,49,64,0)]">
      <Header />
      <Context>{ children }</Context>
    </div>
  )
}

export default MainContainer
