import styles from './Home.module.css'
import { useState } from 'react'
import commonStyles from '../../Styles/Common.module.css'
import { ApiConfig } from '../../Config/Config'
import MainContainer from '../../Components/MainContainer/MainContainer'
import { v4 as uuidv4 } from 'uuid'

const Home = (): JSX.Element => {
  const [clientId, setClientId] = useState('')

  function UrlBuilder (): string {
    const stateUID = uuidv4()
    return `${ApiConfig.ApiBasePath}/oauth2/auth?` +
    `client_id=${clientId.length === 0 ? ApiConfig.ClientId : clientId}&` +
    `redirect_uri=${ApiConfig.RedirectUrl}&` +
    'response_type=code&scope=openid+offline&' +
    `state=${typeof stateUID === 'string' ? stateUID : ''}`
  }

  const beginOauth2 = (): void => {
    window.location.replace(UrlBuilder())
  }

  function clientIdChange (event: any): void {
    setClientId(event.target.value)
  }

  return (
    <MainContainer>
      <div className={styles.HomeContainer}>
        <div className={commonStyles.FoxButtonContainer}>
          <input
            placeholder="Custom Client ID"
            type="text"
            className={commonStyles.FoxInput}
            onChange={clientIdChange}
          />
          <button onClick={beginOauth2} className={commonStyles.FoxButton}>Sign In</button>
        </div>
      </div>
    </MainContainer>
  )
}

export default Home
