import { CSSProperties, useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../Context/theme'
import styles from './Button.module.css'

interface ButtonProps {
  label: string
  disabled?: boolean
  onClick: () => void
}

const Button = ({ label, disabled = false, onClick }: ButtonProps): JSX.Element => {
  const { theme } = useContext(ThemeContext)
  const [hover, setHover] = useState(false)
  const [style, setStyle] = useState<CSSProperties>(calculateStyle(disabled, hover))

  useEffect(() => {
    setStyle(calculateStyle(disabled, hover))
  }, [disabled, hover])

  function calculateStyle (isDisabled: boolean, isHovered: boolean): CSSProperties {
    if (isDisabled) {
      return {
        backgroundColor: theme?.primary_disabled_buttons_color,
        color: theme?.primary_disabled_buttons_font_color
      }
    }

    if (isHovered) {
      return {
        backgroundColor: theme?.primary_hover_buttons_color,
        color: theme?.primary_hover_buttons_font_color,
        cursor: 'pointer'
      }
    }

    return {
      backgroundColor: theme?.primary_buttons_color,
      color: theme?.primary_buttons_font_color
    }
  }

  return (
    <div className={styles.container} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <button
        onClick={onClick}
        className={styles.button}
        disabled={disabled}
        style={style}
      >
        {label}
      </button>
    </div>
  )
}

export default Button
