import { OAuth2Client } from '@ory/hydra-client'
import axios from 'axios'
import { createContext, useMemo, useState } from 'react'
import { S3UrlPrefix, DefaultTheme } from '../Config/Config'

export interface Theme {
  [key: string]: string
}

export interface IThemeContext {
  theme: Theme
  setClient: (client: OAuth2Client) => void
}

export const ThemeContext = createContext<IThemeContext>({
  theme: {},
  setClient: () => {}
})

export const ThemeProvider = ({ children }: {
  children: JSX.Element
}): JSX.Element => {
  const [theme, setTheme] = useState<Theme>({ ...DefaultTheme })
  const [client, setClient] = useState<OAuth2Client>()

  const http = axios.create({
    baseURL: S3UrlPrefix
  })

  useMemo(() => {
    if (client === undefined) return

    const metadata = client?.metadata ?? {}
    type obj = keyof typeof metadata
    const key = 'branding_id' as obj
    const brand: string = metadata[key]

    if (brand === undefined || brand.trim().length === 0) return

    const theme = localStorage.getItem(`theme-${brand}`)
    if (theme !== null) {
      setTheme(JSON.parse(theme))
      return
    }

    void http.get<Theme>(`${brand}.json`)
      .then((resp) => {
        localStorage.setItem(`theme-${brand}`, JSON.stringify(resp.data))
        setTheme(resp.data)
      })
  }, [client])

  return (
    <ThemeContext.Provider value={{ theme, setClient }}>
      {children}
    </ThemeContext.Provider>
  )
}
