import { useState, useEffect, useContext } from 'react'
import { Theme, ThemeContext } from '../../Context/theme'
import styles from './Consent.module.css'
import commonStyles from '../../Styles/Common.module.css'
import { AcceptConsentRequest, ConsentRequest } from '@ory/hydra-client'
import { HydraAdmin } from '../../Config/Config'
import { useNavigate, useSearchParams } from 'react-router-dom'
import MainContainer from '../../Components/MainContainer/MainContainer'
import API from '../../API/'

const ERROR_URL = '/error'

interface ConsentProps {
  clickHandler: (e: any) => void
  scopes?: string[]
  theme: Theme
}

function Select (props: any): JSX.Element {
  return (
    <select multiple id={props.id} onChange={props.onChange} className={styles.ConsentList}>
      { props?.value?.map((v: any, k: any) => <option key={k} value={v}>{v}</option>) }
    </select>
  )
}

function ScopeSelect (props: ConsentProps): JSX.Element {
  return (<div className={styles.ScopeSelect}>
    <div className={commonStyles.FoxTxt} style={{ color: props.theme?.subtexts_font_color }}>Scopes</div>
    <Select id="scopeSelect" value={props?.scopes} onChange={props.clickHandler}/>
  </div>)
}

function Remember (props: ConsentProps): JSX.Element {
  const { theme, clickHandler } = props
  return (<div className={styles.Remember}>
    <div className={commonStyles.FoxTxt} style={{ color: theme?.subtexts_font_color }}>Remember</div>
    <Select id="rememberSelect" value={['remember']} onChange={clickHandler} />
  </div>)
}

function AcceptConsentButton (props: ConsentProps): JSX.Element {
  const { theme, clickHandler } = props
  return (<>
    <label htmlFor="acceptConsentButton" className={styles.HiddenLabel}>accept</label>
    <button
      className={commonStyles.FoxButton}
      id="acceptConsentButton"
      onClick={ clickHandler }
      style={{ backgroundColor: theme?.primary_buttons_color, color: theme?.primary_buttons_font_color, borderColor: theme?.primary_buttons_border_color }}
    >
      Allow
    </button>
  </>)
}

function DenyConsentButton (props: ConsentProps): JSX.Element {
  const { theme, clickHandler } = props
  return (<>
    <label htmlFor="denyConsentButton" className={styles.HiddenLabel}>reject</label>
    <button
      className={commonStyles.FoxButton}
      id="denyConsentButton"
      onClick={ clickHandler }
      style={{ backgroundColor: theme?.primary_buttons_color, color: theme?.primary_buttons_font_color, borderColor: theme?.primary_buttons_border_color }}
    >
      Deny
    </button>
  </>)
}

function Consent (): JSX.Element | null {
  const { theme, setClient } = useContext(ThemeContext)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const consentChallenge = searchParams.get('consent_challenge') ?? ''
  const [selectedScopes, scopesHandler] = handleScopes()
  const [shouldRemember, rememberHandler] = handleRemember()
  const [consentResp, setConsentResp] = useState<ConsentRequest>()

  useEffect(() => {
    API.IdentityHydraAPI.GetConsentRequest(consentChallenge)
      .then((consent) => {
        setConsentResp(consent)
        if (consent.client !== undefined) {
          setClient(consent.client)
        }
      })
      .catch(() => navigate(ERROR_URL))
  }, [])

  if (consentResp?.skip === true) {
    acceptConsent(consentChallenge, consentResp.requested_scope, shouldRemember)
      .then((resp) => window.location.replace(resp.data.redirect_to))
      .catch(() => navigate(ERROR_URL))

    return null
  }

  return (
    <> {consentResp?.skip === false && <MainContainer>
      <div>
        <div className={styles.SelectContainer}>
          <ScopeSelect clickHandler={scopesHandler} scopes={consentResp?.requested_scope} theme={theme} />
          <Remember clickHandler={rememberHandler} theme={theme} />
        </div>
        <div className={styles.ButtonsContainer}>
          <AcceptConsentButton clickHandler={() => {
            HydraAdmin.acceptConsentRequest(
              consentChallenge,
              buildConsentChallengeRequest(selectedScopes, shouldRemember)
            ).then(resp => window.location.replace(resp.data.redirect_to))
              .catch(() => navigate(ERROR_URL))
          }} theme={theme} />
          <DenyConsentButton clickHandler={() => {
            HydraAdmin.rejectConsentRequest(consentChallenge).catch(() => navigate(ERROR_URL))
          }} theme={theme} />
        </div>
      </div>
    </MainContainer>}</>
  )
}

async function acceptConsent (challenge: string, scopes: Iterable<string> | undefined, remember: boolean): Promise<any> {
  return await HydraAdmin.acceptConsentRequest(
    challenge,
    buildConsentChallengeRequest(scopes ?? [], remember)
  )
}

function handleScopes (): [Set<string>, (e: any) => void ] {
  const [selectedScopes, selectScope] = useState<Set<string>>(new Set())
  return [selectedScopes, (e: any) => {
    const updatedScopes = new Set<string>(selectedScopes)
    const scope = e.target?.value

    if (updatedScopes.has(scope)) {
      updatedScopes.delete(scope)
    } else {
      updatedScopes.add(scope)
    }

    selectScope(updatedScopes)
  }]
}

function handleRemember (): [boolean, (e: any) => void] {
  const [shouldRemember, selectRemember] = useState(false)

  return [shouldRemember, (e: any) => {
    selectRemember(e.target?.value != null)
  }]
}

function buildConsentChallengeRequest (scopes: Iterable<string>, remember: boolean): AcceptConsentRequest {
  return {
    grant_scope: Array.from(scopes),
    remember
  }
}

export default Consent
