/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Axios, { AxiosResponse } from 'axios'
import { ApiConfig } from '../../Config/Config'
import MainContainer from '../../Components/MainContainer/MainContainer'
import commonStyles from '../../Styles/Common.module.css'

const ERROR_URL = '/error'

const Callback = (): JSX.Element => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code') ?? ''
  const [accTKn, setAccTkn] = useState('')
  const [rshTkn, setRshTkn] = useState('')
  const [idTkn, setIdTkn] = useState('')

  useEffect(() => {
    if (code === '') {
      navigate(ERROR_URL)
    }
    getTokens(code)
      .then((tknResp) => {
        setAccTkn(`${tknResp.data.access_token ?? 'Missing Access Token'}`)
        setRshTkn(`${tknResp.data.refresh_token ?? 'Missing Refresh Token'}`)
        setIdTkn(`${tknResp.data.id_token ?? 'Missing ID Token'}`)
      })
      .catch(() => navigate(ERROR_URL))
  }, [])

  const beginLogout = (): void => {
    window.location.replace(UrlBuilder(idTkn))
  }

  return (
    <MainContainer>
      <div>
        <h1 className={commonStyles.FoxH1} >Congratulations! You have signed in.</h1>
        <p className={commonStyles.FoxTxtP}><strong>Access Token: </strong>{accTKn}</p>
        <p className={commonStyles.FoxTxtP}><strong>Refresh Token: </strong>{rshTkn}</p>
        <p className={commonStyles.FoxTxtP}><strong>Id Token: </strong>{idTkn}</p>
        <button disabled onClick={beginLogout} className={commonStyles.FoxButton}>Logout</button>
      </div>
    </MainContainer>
  )
}

async function getTokens (code: string): Promise<AxiosResponse> {
  const path = `${ApiConfig.ApiBasePath}/oauth2/token`
  const postBody = {
    grant_type: 'authorization_code',
    scope: 'openid offline',
    code,
    response_type: 'token',
    redirect_uri: ApiConfig.RedirectUrl
  }
  return await Axios.post(path, postBody, {
    headers: {
      Authorization: `Basic ${ApiConfig.BasicAuth}`,
      'X-Api-Key': `${ApiConfig.ApiKey}`
    }
  })
}

function UrlBuilder (idToken: string): string {
  return `${ApiConfig.ApiBasePath}/oauth2/sessions/logout?` +
  `id_token_hint=${idToken}`
}

export default Callback
