import { useState, useEffect, useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from '../../Components/Button/Button'
import { ThemeContext } from '../../Context/theme'
import styles from './Authenticate.module.css'
import commonStyles from '../../Styles/Common.module.css'
import { ApiConfig } from '../../Config/Config'
import MainContainer from '../../Components/MainContainer/MainContainer'
import API from '../../API/'
import { ExtendedLoginRequest, IdpLoginRequest, IdpRegisterRequest } from '../../Interfaces'
import Utils from '../../Utils'

const ERROR_URL = '/error'

/*
 * Missing items
 * - ensure error returns to error page
 * - call register endpoint
 */

const Authenticate = (): JSX.Element => {
  const { theme, setClient } = useContext(ThemeContext)
  const navigate = useNavigate()
  const [disableContinueButton, setDisableContinueButton] = useState(true)
  const [searchParams] = useSearchParams()
  const loginChallenge = searchParams.get('login_challenge') ?? ''
  const [loginResp, setLoginResp] = useState<ExtendedLoginRequest>()
  const [userEmail, setUserEmail] = useState('')
  const [renderStep, setRenderStep] = useState('enterEmail')
  const steps = {
    signUp: 'signUp',
    signIn: 'signIn',
    enterEmail: 'enterEmail'
  }
  const [userPassword, setUserPassword] = useState('')

  // divs and components

  const BackArrow = (): JSX.Element => {
    return (
      <div className={styles.BackArrowContainer}>
        <svg
          className={styles.BackArrowSVG}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 233.333 155.555"
          width="50" height="33.333"
          focusable={true}
          cursor="pointer"
          onClick={onBackArrowClick}
        >
            <path d="M77.918 140v-38.888h77.778V38.888H77.918V0L0 69.525 77.918 140z"/>
        </svg>
      <a className={styles.BackArrowText}>Back</a>
      </div>
    )
  }

  const signInForm = (
    <div className={styles.AuthenticateForm}>
      <BackArrow/>
      <h1 className={commonStyles.FoxH1Text} style={{ color: theme?.title_text_font_color }}>Welcome Back!</h1>
      <div className={styles.AuthenticateElement}>
        <input
          placeholder={userEmail}
          type="email"
          className={commonStyles.FoxInput}
          style={{ color: theme?.text_input_font_color, borderColor: theme?.text_input_border_color, backgroundColor: theme?.text_input_background_color }}
          disabled
        />
      </div>
      <div className={styles.AuthenticateElement}>
        <input
          placeholder='Password'
          type="password"
          className={commonStyles.FoxInput}
          style={{ color: theme?.text_input_font_color, borderColor: theme?.text_input_border_color, backgroundColor: theme?.text_input_background_color }}
          onChange={passwordChange}
        />
      </div>
      <div>
        <Button label='Log In' onClick={processLogin} />
      </div>
    </div>
  )

  const signUpForm = (
    <div className={styles.AuthenticateForm}>
      <BackArrow/>
      <h1 className={commonStyles.FoxH1Text} style={{ color: theme?.title_text_font_color }}>Set up your password</h1>
      <div className={styles.AuthenticateElement}>
        <input
          placeholder={userEmail}
          type="email"
          className={commonStyles.FoxInput}
          style={{ color: theme?.text_input_font_color, borderColor: theme?.text_input_border_color, backgroundColor: theme?.text_input_background_color }}
          disabled
        />
      </div>
      <div className={styles.AuthenticateElement}>
        <input
          placeholder='Password'
          type="text"
          className={commonStyles.FoxInput}
          style={{ color: theme?.text_input_font_color, borderColor: theme?.text_input_border_color, backgroundColor: theme?.text_input_background_color }}
          onChange={passwordChange}
        />
      </div>
      <div>
        <Button label='Sign Up' onClick={processRegister} />
      </div>
    </div>
  )

  const enterEmailForm = (
    <div className={styles.AuthenticateForm}>
      <h1 className={commonStyles.FoxH1Text} style={{ color: theme?.title_text_font_color }}>Create Account or Sign In</h1>
      <div className={styles.AuthenticateElement}>
        <input
          placeholder='Email'
          type="email"
          id={'email_input'}
          className={commonStyles.FoxInput}
          style={{ color: theme?.text_input_font_color, borderColor: theme?.text_input_border_color, backgroundColor: theme?.text_input_background_color }}
          onChange={emailChange}
        />
      </div>
      <div>
        <Button
          label='Continue With Fox Account'
          disabled={disableContinueButton}
          onClick={continueWithFoxAcc} />
        <Button label='Cancel' onClick={cancelLogin} />
      </div>
      {/* TODO: Add socials
      <div className={commonStyles.FoxElementText}>or continue with</div>
      <div className={commonStyles.FoxElementText}>Socials Go here</div> */}
    </div>
  )

  // Functions onChange and Click

  function onBackArrowClick (): void {
    setRenderStep(steps.enterEmail)
  }

  function emailChange (event: any): void {
    if (Utils.EmailRegex(event.target.value)) {
      setDisableContinueButton(false)
      setUserEmail(event.target.value)
      return
    }
    setDisableContinueButton(true)
  }

  function passwordChange (event: any): void {
    setUserPassword(event.target.value)
  }

  function continueWithFoxAcc (): void {
    API.EmailStatusAPI.CheckEmailStatus(userEmail)
      .then((d) => {
        if (d.data.found) {
          setRenderStep(steps.signIn)
        } else {
          setRenderStep(steps.signUp)
        }
      })
      .catch(e => console.log(loginResp))
  }

  function processLogin (): void {
    asyncProcessLogin()
      .catch(e => {
        console.error(e)
        navigate(ERROR_URL)
      })
  }

  async function asyncProcessLogin (): Promise<void> {
    if (userPassword === '') {
      console.error('Password is empty.')
      return
    }

    const {
      Environment: env,
      LoginCaptchaEnabled: captchaEnabled,
      CaptchaSiteKey: siteKey
    } = ApiConfig
    if (env !== 'PROD') {
      // TODO: Emmit source maps dev and qa environments to allow debugging without console logging sensitive data.
      console.log(loginResp)
      console.log(userEmail)
      console.log(userPassword)
    }

    const loginRequest: IdpLoginRequest = {
      email: userEmail,
      password: userPassword,
      login_challenge: loginChallenge
    }

    try {
      const recaptchaRequired = loginResp?.recaptcha_required ?? false
      if (captchaEnabled && recaptchaRequired) {
        window.grecaptcha.enterprise.ready(async () => {
          loginRequest.recaptcha_token = await window.grecaptcha.enterprise.execute(siteKey, { action: 'idp_login' })
          await doLogin(loginRequest)
        })
      } else {
        await doLogin(loginRequest)
      }
    } catch (e) {
      console.error(e)
      navigate(ERROR_URL)
    }
  }

  async function doLogin (loginRequest: IdpLoginRequest): Promise<void> {
    try {
      await API.FoxIdpAPI.IdpLogin(loginRequest)
      const data = await API.IdentityHydraAPI.AcceptLoginRequest(
        loginRequest.login_challenge,
        loginRequest.email
      )
      window.location.replace(data.redirect_to)
    } catch (e) {
      console.error(e)
      navigate(ERROR_URL)
    }
  }

  async function doRegister (registerRequest: IdpRegisterRequest): Promise<void> {
    try {
      await API.FoxIdpAPI.IdpRegister(registerRequest)
      const data = await API.IdentityHydraAPI.AcceptLoginRequest(
        registerRequest.login_challenge,
        registerRequest.email
      )
      window.location.replace(data.redirect_to)
    } catch (e) {
      console.error(e)
      navigate(ERROR_URL)
    }
  }

  function cancelLogin (): void {
    API.IdentityHydraAPI.RejectLoginRequest(loginChallenge).finally(() => {})
  }

  function processRegister (): void {
    console.log('test')

    asyncProcessRegister()
      .catch(e => {
        console.error(e)
        navigate(ERROR_URL)
      })
  }

  async function asyncProcessRegister (): Promise<void> {
    if (userPassword === '') {
      console.error('Password is empty.')
      return
    }

    const {
      Environment: env,
      LoginCaptchaEnabled: captchaEnabled,
      CaptchaSiteKey: siteKey
    } = ApiConfig
    if (env !== 'PROD') {
      // TODO: Emmit source maps dev and qa environments to allow debugging without console logging sensitive data.
      console.log(loginResp)
      console.log(userEmail)
      console.log(userPassword)
    }

    const registerRequest: IdpRegisterRequest = {
      email: userEmail,
      password: userPassword,
      login_challenge: loginChallenge
    }

    try {
      const recaptchaRequired = loginResp?.recaptcha_required ?? false
      if (captchaEnabled && recaptchaRequired) {
        window.grecaptcha.enterprise.ready(async () => {
          registerRequest.recaptcha_token = await window.grecaptcha.enterprise.execute(siteKey, { action: 'idp_login' })
          await doRegister(registerRequest)
        })
      } else {
        await doRegister(registerRequest)
      }
    } catch (e) {
      console.error(e)
      navigate(ERROR_URL)
    }
  }

  function getLoginChallengeInfo (): void {
    API.IdentityHydraAPI.GetLoginRequestInfo(loginChallenge)
      .then((loginInfo) => {
        setLoginResp(loginInfo.data)
        setClient(loginInfo.data.client)
        if (ApiConfig.LoginCaptchaEnabled && loginInfo.data.recaptcha_required) {
          const script = document.createElement('script')
          script.src = `https://www.google.com/recaptcha/enterprise.js?render=${ApiConfig.CaptchaSiteKey}`
          script.async = true
          document.head.appendChild(script)
        }
      })
      .catch(() => navigate(ERROR_URL))
  }

  // initial call

  useEffect(getLoginChallengeInfo, [])

  // rendering function for dynamic change

  function renderForm (renderStep: string): JSX.Element {
    switch (renderStep) {
      case steps.signIn:
        return signInForm
      case steps.signUp:
        return signUpForm
      default:
        return enterEmailForm
    }
  }

  // return element
  return (
    <MainContainer>
      { renderForm(renderStep) }
    </MainContainer>
  )
}

export default Authenticate
