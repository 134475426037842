import { Configuration, AdminApi } from '@ory/hydra-client'

const configuration = new Configuration({
  basePath: process.env.REACT_APP_HYDRA_URL,
  accessToken: process.env.REACT_APP_HYDRA_ACCESS_TOKEN
})

const HydraAdmin = new AdminApi(configuration)

const ApiConfig = {
  ApiBasePath: `${process.env.REACT_APP_API_BASEPATH ?? 'localhost:8080'}`,
  ApiKey: `${process.env.REACT_APP_APIKEY ?? ''}`,
  ClientId: `${process.env.REACT_APP_CLIENT_ID ?? ''}`,
  RedirectUrl: `${process.env.REACT_APP_REDIRECT_URL ?? ''}`,
  BasicAuth: `${process.env.REACT_APP_BASICAUTH ?? ''}`,
  Timeout: Number(process.env.REACT_APP_TIMEOUT) ?? 1500,

  LoginCaptchaEnabled: Boolean(process.env.REACT_APP_LOGIN_CAPTCHA_ENABLED) ?? false,
  CaptchaSiteKey: `${process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ''}`,
  Environment: `${process.env.REACT_APP_ENVIRONMENT ?? 'PROD'}`
}

const S3UrlPrefix = process.env.REACT_APP_S3_URL_PREFIX ?? ''

const DefaultTheme = {
  context_background_color: 'transparent',
  header_background_color: '#1b3140',
  header_logo: 'https://s.foxdcg.com/foxnow/web/v3-2/img/network/fox.png?fit=inside%7C*:80',

  primary_buttons_color: '#0086bd',
  primary_buttons_font_color: '#ffffff',
  primary_hover_buttons_color: '#ffffff',
  primary_hover_buttons_font_color: '#000000FF',
  primary_disabled_buttons_color: '#0086bd',
  primary_disabled_buttons_font_color: '#FFFFFF7F',
  primary_disabled_hover_buttons_color: '#0086BDFF',
  primary_disabled_hover_buttons_font_color: '#FFFFFF7F',

  text_input_background_color: 'transparent',
  text_input_border_color: '#007ee4',
  text_input_font_color: '#ffffff',
  title_text_font_color: '#ffffff',
  subtexts_font_color: '#d8d8d8'
}

export { HydraAdmin, ApiConfig, S3UrlPrefix, DefaultTheme }
